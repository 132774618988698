<template>
  <v-card class="mx-auto" :disabled="table_loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                table_loading ? '' : `(${table_data.length})`
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="table_loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item }">
              <b v-text="item.index" />
            </template>
            <template v-slot:item.doctor.user_id="{ item }">
              {{ item.doctor.user_id ? "SI" : "NO" }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.rs_service_bill.paid_amount="{ item }">
              {{
                item.rs_service_bill && item.rs_service_bill.paid_amount
                  ? numberFormat(item.rs_service_bill.paid_amount)
                  : ""
              }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="info"
                    :to="{
                      name: `pend_cobros.detalle`,
                      params: { rs_service_id: item.id },
                    }"
                    v-on="on"
                    x-small
                    dark
                    fab
                  >
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.pending_services.update &&
                  !item.rs_service_bill
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="amber darken-2"
                    v-on="on"
                    x-small
                    dark
                    fab
                    :to="{
                      name: 'pend_cobros.documentacion',
                      params: { rs_service_id: item.id },
                    }"
                  >
                    <v-icon> mdi-file-code </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar documentación'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  login.permissions.pending_services.create &&
                  !item.doctor.bank_account_pdf
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.doctor_prerevision_rejected
                        ? 'error'
                        : 'amber darken-3'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="doctorPrerevisionDialog(item)"
                  >
                    <v-icon> mdi-file-sign </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar cuenta bancaria'" />
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.rs_service_notes_total > 0
                        ? 'pink darken-1'
                        : 'grey lighten-1'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="noteDialog(item)"
                  >
                    <v-icon> mdi-note-text </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Nota(s) administrativa(s)'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="doctor_prerevision_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="doctor_prerevision_dialog_loading"
        :loading="doctor_prerevision_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_prerevision_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_prerevision_data">
          <v-row>
            <v-col cols="12" />
            <v-col
              v-if="doctor_prerevision_data.doctor_prerevision_rejected"
              cols="12"
            >
              <v-alert type="error" class="mb-0" dense>
                <v-row dense>
                  <v-col cols="12">
                    <b>CARGA PREVIA RECHAZADA</b>
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Banco"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected.bank
                          .bank
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Número de cuenta"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .account_number
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="CLABE"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .clabe
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <span
                      class="text-caption font-weight-bold"
                      v-text="'Cuenta bancaria (PDF)'"
                    />
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :href="
                            url_documents +
                            '/doctors/' +
                            doctor_prerevision_data.doctor_prerevision_rejected
                              .bank_account_pdf
                          "
                          target="_blank"
                        >
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Ver'" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <ViewData
                      label="Observación"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .observation
                      "
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'GENERAL'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="`IDM ${doctor_prerevision_data.doctor_id} | ${doctor_prerevision_data.doctor_name}`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="doctor_prerevision_data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="doctor_prerevision_data.provider_trade_name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'BANCO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_prerevision_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Banco*"
                          v-model="doctor_prerevision_data.bank_id"
                          :items="banks"
                          item-value="id"
                          :item-text="(v) => v.bank"
                          :loading="banks_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Número de cuenta*"
                          v-model="doctor_prerevision_data.account_number"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="CLABE*"
                          v-model="doctor_prerevision_data.clabe"
                          type="text"
                          maxlength="18"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="Cuenta bancaria (PDF)*"
                          v-model="
                            doctor_prerevision_data.document_bank_account_0
                          "
                          accept=".pdf"
                          :rules="rules.required"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="amber darken-3"
                          dark
                          @click.prevent="doctorPrerevisionSubmit"
                          small
                        >
                          CARGAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="note_dialog" scrollable persistent max-width="900">
      <v-card
        tile
        :disabled="note_dialog_loading"
        :loading="note_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> NOTA(S) ADMINISTRATIVA(S) </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="note_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="note_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`SERVICIO | FOLIO ${note_data.folio_full}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Médico"
                        :value="
                          'IDM ' +
                          note_data.doctor_id +
                          ' | ' +
                          note_data.doctor_name
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Asegurado"
                        :value="note_data.insured_full_name"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col v-if="rs_service_notes" cols="12">
                      <v-row
                        v-for="(rs_service_note, i) in rs_service_notes"
                        :key="i"
                        dense
                      >
                        <v-col cols="12">
                          <ViewData
                            :label="`${i + 1}. Nota administrativa`"
                            :value="rs_service_note.rs_service_note"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="Usuario"
                            :value="rs_service_note.created_by.email"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="F. creación"
                            :value="rs_service_note.created_at"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-form
                        v-on:submit.prevent
                        ref="note_form"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              v-model="note_data.rs_service_note"
                              label="Nueva nota administrativa*"
                              dense
                              rows="2"
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              block
                              color="pink darken-1"
                              dark
                              @click.prevent="noteSubmit"
                              small
                            >
                              AGREGAR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      table_loading: false,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Folio", filterable: true, value: "folio_full" },
        { text: "F. Creación", filterable: true, value: "created_at" },
        { text: "Asegurado", filterable: true, value: "insured_full_name" },
        { text: "Contratante", filterable: true, value: "contractor_name" },
        { text: "IDM", filterable: true, value: "doctor_id" },
        { text: "Usr. Sis.", filterable: true, value: "doctor.user_id" },
        { text: "Médico", filterable: true, value: "doctor_name" },
        {
          text: "Especialidad",
          filterable: true,
          value: "specialty_type.specialty_type",
        },
        {
          text: "Consult. | Hosp.",
          filterable: true,
          value: "provider_trade_name",
        },
        { text: "Monto", filterable: true, value: "amount" },
        { text: "Gen. carta", filterable: true, value: "letter_generated" },
        { text: "Doc. ID", filterable: true, value: "rs_service_bill.folio" },
        { text: "", value: "action", sortable: false, width: "245px" },
      ],
      rules: rules(),
      doctor_prerevision_data: null,
      doctor_prerevision_dialog: false,
      doctor_prerevision_dialog_loading: false,
      banks: [],
      banks_loading: true,
      note_data: null,
      note_dialog: false,
      note_dialog_loading: false,
      rs_service_notes: null,
      item: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.table_loading = true;
      this.table_data = [];
      Axios.post(
        `${URL_API}/rss/services/bills/pending`,
        {},
        headersToken(this.login.token)
      ).then((resp) => {
        this.table_data = resp.data.data;
        this.table_loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    doctorPrerevisionDialog(item) {
      this.doctor_prerevision_data = {
        id: null,
        active: true,
        bank_id: null,
        account_number: "",
        clabe: "",
        document_bank_account_0: null,
        doctor_id: item.doctor_id,
        doctor_name: item.doctor_name,
        specialty_type: item.specialty_type.specialty_type,
        provider_trade_name: item.provider_trade_name,
        doctor_prerevision_rejected: item.doctor_prerevision_rejected,
        doctor_prerevision_rejected_id: item.doctor_prerevision_rejected
          ? item.doctor_prerevision_rejected.id
          : null,
      };
      this.doctor_prerevision_dialog_loading = false;
      this.doctor_prerevision_dialog = true;
    },
    doctorPrerevisionSubmit() {
      if (this.$refs.doctor_prerevision_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma cargar el recibo de pago?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.doctor_prerevision_dialog_loading = true;
              Axios.post(
                `${URL_API}/doctors/prerevision`,
                toFormData(this.doctor_prerevision_data),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.doctor_prerevision_dialog = false;
                  this.getTableData();
                } else {
                  console.log(resp.data.message);
                }

                this.doctor_prerevision_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },

    noteDialog(item) {
      this.rs_service_notes = null;
      this.item = item;
      this.note_data = {
        id: null,
        rs_service_note: null,
        rs_service_id: item.id,
        folio_full: item.folio_full,
        doctor_id: item.doctor_id,
        doctor_name: item.doctor_name,
        insured_full_name: item.insured_full_name,
      };
      this.note_dialog_loading = true;
      this.note_dialog = true;

      Axios.post(
        `${URL_API}/rss/services/notes`,
        {
          rs_service_id: item.id,
        },
        headersToken(this.login.token)
      ).then((resp) => {
        this.rs_service_notes = resp.data.data;
        this.note_dialog_loading = false;
      });
    },
    noteSubmit() {
      if (this.$refs.note_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma agregar nota administrativa?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.note_dialog_loading = true;
              Axios.post(
                `${URL_API}/rss/services/notes/save`,
                this.note_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.item.rs_service_notes_total++;
                  this.note_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.note_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=banks&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.banks = resp.data.data;
      this.banks_loading = false;
    });

    this.getTableData();
  },
};
</script>